// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import TrashSvg from '../icons/action/Trash';
var Trash = createSvgIcon({
    as: TrashSvg,
    ariaLabel: 'trash',
    category: 'action',
    displayName: 'Trash'
});
export default Trash;
