// require('dotenv').config();

import { URL } from './env';
export default io.connect(URL);
export let token = localStorage.getItem('token');
export let userId = localStorage.getItem('userId');
export const setToken = (newToken) => token = newToken;
export const setUserId = (newUserId) => userId = newUserId;

export const setLogout = (refresh = true) => {
    token = '';
    userId = '';
    localStorage.clear("");
    sessionStorage.clear("");
    localStorage.setItem('isCookies', true);
    for (const key of ["token", "hash", "userId"]) document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    if (refresh) window.location.replace(window.origin);
};

export const postRequest = async (endpoint, obj, noLoader, animate) => {
    let fileUploadAnimation;
    const spinner = document.getElementById("e1jygW4piZH1");
    if (endpoint !== '/question/saveCV') {
        if (endpoint == '/question/newSaveAnswers' || endpoint == '/question/saveAnswers' || endpoint == '/question/newUploadFile') fileUploadAnimation = document.getElementById("fileUploadRepository");
        else if (endpoint == '/company/uploadFiles' || endpoint == '/company/uploadFile') fileUploadAnimation = document.getElementById("fileUploadFilingAssistant");
        else if (endpoint == '/company/allocateFiles') fileUploadAnimation = document.getElementById("filingAssistant2Repository");
        else fileUploadAnimation = document.getElementById("syncRepositoryDataRoom");
    }
    if (!endpoint.includes('/company/generateAIReport') && !noLoader) spinner.classList.remove("op0");
    if (endpoint == '/user/reportBug' || endpoint == '/user/updateProfile' || !noLoader) fileUploadAnimation = spinner
    if (animate === "noAnimation") fileUploadAnimation = null
    try {
        let myHeaders = new Headers();
        if (userId) myHeaders.append("id", userId);
        if (token) myHeaders.append("Authorization", `Bearer ${token}`);
        //let urlencoded = new URLSearchParams();
        //for (const key of Object.keys(obj)) urlencoded.append(key, obj[key]);
        const formData = new FormData();
        if ((endpoint.includes("sync") && endpoint.toLowerCase().includes("dataroom")) ||
            (endpoint == '/company/allocateFiles'))
            fileUploadAnimation && fileUploadAnimation?.classList.remove("op0");
        Object.entries(obj).forEach(arr => {
            if (arr[0] === "attachment" && obj.attachment.length) {
                fileUploadAnimation && fileUploadAnimation?.classList.remove("op0");
                for (const file of obj.attachment) formData.append("attachment", file);
            } else if (arr[0] === "files" && obj.files.length) {
                fileUploadAnimation && fileUploadAnimation?.classList.remove("op0");
                formData.append(arr[0], JSON.stringify(arr[1]));
            } else if (typeof arr[1] == "object") formData.append(arr[0], JSON.stringify(arr[1]));
            else formData.append(...arr);
        });

        let requestOptions = { method: 'POST', headers: myHeaders, body: formData, redirect: 'follow' };

        let response = await fetch(`${URL}/api${endpoint}`, requestOptions).then(response => response.json());
        // TODO: if code 401 then logout
        if (response['code'] == 401) setLogout();
        else if (response['code'] != 200) showMessage(response['message']);

        return response;
    } catch (e) {
        showMessage('Something went wrong')
    } finally {
        if (!noLoader) spinner.classList.add('op0');
        fileUploadAnimation && fileUploadAnimation?.classList.add('op0');
    }
};

export const getRequest = (endpoint, noShow) => {
    return new Promise(async (resolve) => {
        const spinner = document.getElementById('e1jygW4piZH1');
        spinner.classList.remove('op0');
        try {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
            if (userId) myHeaders.append('id', userId);
            if (token) myHeaders.append('Authorization', `Bearer ${token}`);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            let response = await fetch(`${URL}/api${endpoint}`, requestOptions).then(response => response.json())
            if (response['code'] == 401) setLogout();
            else if (response['code'] != 200 && noShow) return;
            else if (response['code'] != 200) return showMessage(response['message']);
            return resolve(response);

        } catch (e) { showMessage('Something went wrong') } finally { spinner.classList.add('op0') }
    });
};

export const showMessage = async message => {
    let popupTimeout;
    if (!document.getElementById('popup-message'))
        return setTimeout(() => showMessage(message), 1000);

    const popupEl = document.getElementById('popup-message');

    let timeout = 0;
    if (!popupEl.classList.toString().includes('hidden')) {
        popupEl.classList.add('hidden');
        timeout = 600;
    }
    setTimeout(() => {
        popupEl.innerText = message;
        popupEl.classList.remove('hidden');
        clearTimeout(popupTimeout);
        popupTimeout = setTimeout(() => popupEl.classList.add('hidden'), 5000);
    }, timeout);
};