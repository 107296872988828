import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogHero from "../../components/blogs/BlogHero";
import BlogGrid from "../../components/blogs/BlogGrid";
import { filterBlogs } from "../../reducers/blogReducer";
import style from "./BlogPage.module.css"

function BlogPage() {

    const { tab, blogPosts, featuredPost, filtered, q, rawData } = useSelector((state) => state.blogs)
    const dispatch = useDispatch()

    const handleActiveTab = (tabName) => {
        const coreKeyword = tabName.split(" ")[0];
        const regex = new RegExp(`\\b${coreKeyword.slice(0, -1)}[aeiou]*l\\b|\\b${coreKeyword}\\b`, "i")

        const filteredData = tabName == "All" ? rawData : rawData.filter((blog) => regex.test(blog.category))
        dispatch(
            filterBlogs({
                tab: tabName,
                blogPosts: filteredData,
                filtered: (q === "" && tabName === "All") ? false : true
            })
        )
    }

    const handleSearch = (searchTerm) => {
        const regex = new RegExp(searchTerm, "i")
        const filteredData = rawData.filter(blog => regex.test(blog.title))
        dispatch(
            filterBlogs({
                blogPosts: filteredData,
                filtered: (searchTerm === "" && tab === "All") ? false : true
            })
        )
    }

    return (
        <main className={style.blogPage}>
            <BlogHero handleActiveTab={handleActiveTab} handleSearch={handleSearch} tab={tab} />
            <BlogGrid blogPosts={blogPosts} featuredPost={featuredPost} filtered={filtered} />
        </main>
    );
}

export default BlogPage;
