import { AnimatePresence, motion } from "framer-motion";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import * as ROUTES from "../constants/routes";

import Home from "../pages/Home";
import Login from "../pages/Login";
import AboutUs from "../pages/AboutUs";
import Register from "../pages/Register";
import ContactUs from "../pages/ContactUs";
import PartnerProgram from "../pages/PartnerProgram";
import { Privacy, CookiePolicy, DataEthics, AllUse, DMCA } from "../pages/newPolicies";
import { AcceptableUse, CompanyTerms, IndividualsTerms } from "../pages/termsAndCondition";

import HomePage from "../pages/landingPage/homePage/HomePage";
import ExitReadyCompanies from "../pages/ExitReadyCompanies";
import GrowthReadyCompanies from "../pages/GrowthReadyCompanies";
import InvestmentReadyCompanies from "../pages/InvestmentReadyCompanies";

import Lawyers from "../pages/Lawyers";
import Accountants from "../pages/Accountants";

import AngelNetworks from "../pages/AngelNetworks";
import AngelInvestors from "../pages/AngelInvestors";
import PrivateEquityFirms from "../pages/PrivateEquityFirms";
import VentureCapitalFirms from "../pages/VentureCapitalFirms";

import Terms from "../pages/Terms";
import Policies from "../pages/Policies";
import NotFound from "../pages/NotFound";

import Group from "../pages/logged_in/Group";
import Billing from "../pages/logged_in/Billing";
import Support from "../pages/logged_in/Support";
import Profile from "../pages/logged_in/Profile";
import ESGPack from "../pages/logged_in/ESGPack";
import Admin from "../pages/logged_in/Admin/index";
import Dashboard from "../pages/logged_in/Dashboard";
import Company from "../pages/logged_in/Company/index";
import Report from "../pages/logged_in/Company/Report";
import Advisor from "../pages/logged_in/Advisor/index.js";
import IndustryPack from "../pages/logged_in/IndustryPack";
import ExpansionPack from "../pages/logged_in/ExpansionPack";
import DirectorsPack from "../pages/logged_in/DirectorsPack";
import CompanyWrapper from "../pages/logged_in/Company/CompanyWrapper";
import InvestorSettings from "../pages/logged_in/Advisor/InvestorSettings";
import ExpansionReferencing from "../pages/logged_in/ExpansionReferencing";

import PricingPlans from "../pages/PricingPlans";
import LandingHomePEs from "../pages/landingPage/homePage/PEs";
import LandingHomeVcs from "../pages/landingPage/homePage/VCs";
import LandingHomeAngels from "../pages/landingPage/homePage/Angels";
import LandingHomeLawyers from "../pages/landingPage/homePage/Lawyers";
import LandingHomeCompanies from "../pages/landingPage/homePage/Companies";
import LandingHomeAccountant from "../pages/landingPage/homePage/Accountants";
import LandingHomeAngelNetworks from "../pages/landingPage/homePage/AngelsNetworks";

import Blogs from "../pages/blogs/index.jsx";
import BlogDetailPage from "../pages/blogs/BlogDetailPage/index.jsx";
// import { useIntercomCustomLauncher } from "../hooks/useIntercomCustomLauncher.js";

export default function AnimatedRoutes() {
	const location = useLocation();
	// useIntercomCustomLauncher(require("../../images/diligentsiaGirl.png"));

	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				{/* <Route path={ROUTES.HOME} element={<Home />} /> */}
				<Route path={ROUTES.HOME} element={<HomePage />} />
				<Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
				<Route path={ROUTES.LANDING_PAGE} element={<LandingHomeCompanies />} />
				<Route path={ROUTES.LOGIN} element={<Login />} />
				<Route path={ROUTES.REGISTER} element={<Register />} />

				{/* BLOGS */}
				<Route path={ROUTES.BLOGS} element={<Blogs />} />
				<Route path={ROUTES.BLOG_DETAILS} element={<BlogDetailPage />} />

				{/* Landing page routes */}
				<Route path={ROUTES.LANDING_PAGE_COMPANIES} element={<LandingHomeCompanies />} />
				<Route path={ROUTES.LANDING_PAGE_ACCOUNTANT} element={<LandingHomeAccountant />} />
				<Route path={ROUTES.LANDING_PAGE_LAWYER} element={<LandingHomeLawyers />} />
				<Route path={ROUTES.LANDING_PAGE_VCS} element={<LandingHomeVcs />} />
				<Route path={ROUTES.LANDING_PAGE_PES} element={<LandingHomePEs />} />
				<Route path={ROUTES.LANDING_PAGE_ANGEL_NETWORKS} element={<LandingHomeAngelNetworks />} />
				<Route path={ROUTES.LANDING_PAGE_ANGELS} element={<LandingHomeAngels />} />

				<Route path='/reset_password/:hash' element={<Login />} />
				<Route path='/newMember/:hash' element={<LandingHomeCompanies />} />
				<Route path='/verifyEmail/:hash' element={<LandingHomeCompanies />} />
				<Route path='/verifyAdvisor/:hash' element={<LandingHomeCompanies />} />
				<Route path='/investorToken/:hash' element={<LandingHomeCompanies />} />
				<Route path='/newAdvisorCompany/:hash' element={<LandingHomeCompanies />} />
				<Route path='/newInvestorCompany/:hash' element={<LandingHomeCompanies />} />
				<Route path='/hashToken/:hash/company/:hash' element={<LandingHomeCompanies />} />

				<Route path={ROUTES.ABOUT} element={<AboutUs />} />
				<Route path={ROUTES.INVESTMENT_READY} element={<InvestmentReadyCompanies />} />
				<Route path={ROUTES.GROWTH_READY} element={<GrowthReadyCompanies />} />
				<Route path={ROUTES.EXIT_READY} element={<ExitReadyCompanies />} />

				<Route path={ROUTES.LAWYERS} element={<Lawyers />} />
				<Route path={ROUTES.ACCOUNTANTS} element={<Accountants />} />

				<Route path={ROUTES.ANGEL_INVESTORS} element={<AngelInvestors />} />
				<Route path={ROUTES.ANGEL_NETWORKS} element={<AngelNetworks />} />
				<Route path={ROUTES.VENTURE_CAPITAL} element={<VentureCapitalFirms />} />
				<Route path={ROUTES.PRIVATE_EQUITY} element={<PrivateEquityFirms />} />

				{/* LOGGED IN */}
				<Route path={ROUTES.BILLING} element={<Billing />} />
				<Route path={ROUTES.COMPANY} element={<Company />} />
				<Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
				<Route path={ROUTES.ADVISOR} element={<Advisor />} />
				<Route path={ROUTES.INVESTOR} element={<Advisor />} />
				<Route path={ROUTES.ADVISORSETTINGS} element={<InvestorSettings />} />
				<Route path={ROUTES.INVESTORSETTINGS} element={<InvestorSettings />} />

				<Route path='/gDriveCallBack' element={<CompanyWrapper />} />
				<Route path='/dropBoxCallBack' element={<CompanyWrapper />} />
				<Route path='/sharePointCallBack' element={<CompanyWrapper />} />
				<Route path={ROUTES.GROUP} element={<Group />} />
				<Route path={ROUTES.PROFILE} element={<Profile />} />
				<Route path={ROUTES.SUPPORT} element={<Support />} />
				<Route path={ROUTES.REPORT} element={<Report />} />
				<Route path={ROUTES.EXPANSIONPACK} element={<ExpansionPack />} />
				<Route path={ROUTES.DIRECTORSPACK} element={<DirectorsPack />} />
				<Route path={ROUTES.INDUSTRYPACK} element={<IndustryPack />} />
				<Route path={ROUTES.ESGPACK} element={<ESGPack />} />
				<Route path={ROUTES.ExpansionReferencing} element={<ExpansionReferencing />} />

				{/* LOGGED IN AS ADMIN */}
				<Route path={ROUTES.ADMIN} element={<Admin />} />

				{/* New Terms and Conditions Section */}
				<Route path={ROUTES.TERMS_CONDITION_COMPANY} element={<CompanyTerms />} />
				<Route path={ROUTES.TERMS_CONDITION_INDIVIDUALS_TERMS} element={<IndividualsTerms />} />
				<Route path={ROUTES.TERMS_CONDITION_ACCEPTABLE_USE} element={<AcceptableUse />} />

				{/* New Policies Section */}
				<Route path={ROUTES.POLICY_COOKIE} element={<CookiePolicy />} />
				<Route path={ROUTES.POLICY_DMCA} element={<DMCA />} />
				<Route path={ROUTES.POLICY_PRIVACY} element={< Privacy />} />
				<Route path={ROUTES.POLICY_DATA_ETHICS} element={<DataEthics />} />
				<Route path={ROUTES.POLICY_ALL_USE} element={<AllUse />} />

				{/* New About us */}
				<Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
				{/* New Pricing Plan */}
				<Route path={ROUTES.PRICING_PLAN} element={<PricingPlans />} />
				{/* Contact us page */}
				<Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
				{/* Partner program */}
				<Route path={ROUTES.PARTNER_PROGRAM} element={<PartnerProgram />} />

				<Route path={ROUTES.TERMS} element={<Terms />} />
				<Route path={ROUTES.POLICIES} element={<Policies />} />
				<Route path="* " element={<NotFound />} />
			</Routes>
		</AnimatePresence >
	);
}
