import React from "react";

function ContentSection({ keyName, value, styles }) {
  if (keyName === "title") return <h2>{value}</h2>;
  if (keyName === "subTitle") return <h3>{value}</h3>;
  if (keyName === "introduction") return <p>{value}</p>;
  if (keyName === "sections" && Array.isArray(value)) {
    return value.map((section, sectionIndex) => {
      return (
        <div key={sectionIndex} className={`${section.pointSideImage && "df aifs"}`}>
          {section.title && <h2>{section.title}</h2>}
          {section.subTitle && <h3>{section.subTitle}</h3>}
          {section.introduction && (
            <p>
              {section.introduction
                .split("Contact us today")
                .map((part, index, arr) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < arr.length - 1 && (
                      <>
                        <br></br>
                        <strong>
                          <i>Contact us today</i>
                        </strong>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </p>
          )}
          {section.points && (
            <ul style={{ ...(section.pointSideImage && { width: "75%", paddingRight: "30px" }) }} className={styles.customUL}>
              {section.listHeading && <h2>{section.listHeading}</h2>}
              {section.points.map((point, pointIndex) => (
                <li key={pointIndex}>
                  <p className="df aifs jcfs">
                    {
                      point.includes("<break>") ? <>
                        <span className={styles.listTopic}>
                          {point.split("<break>")[0]}
                        </span> {point.split("<break>")[1]} {point.split("<break>")[2] ? point.split("<break>")[2] : ""}
                      </> : point.includes(":") ? (
                        <>
                          <span className={styles.listTopic}>
                            {point.split(":")[0]}
                          </span>
                          : {point.split(":")[1]}{point.split(":")[2] ? `: ${point.split(":")[2]}` : ""}
                        </>
                      ) : (
                        <span className={styles.listTopic}>{point}</span>
                      )}
                  </p>
                </li>
              ))}
            </ul>
          )}
          {section.pointSideImage && <img style={{ width: "25%", objectFit: 'contain', marginTop: '2rem' }} src={section.pointSideImage} alt="certificates" />}
        </div>
      )
    });
  }
  if (keyName === "list" && Array.isArray(value)) {
    return value.map((listData, listDataIndex) => (
      <div key={listDataIndex}>
        {listData.title && <h2>{listData.title}</h2>}
        {listData.subTitle && <h3>{listData.subTitle}</h3>}
        {listData.introduction && <p>{listData.introduction}</p>}
        {listData.points && (
          <ul className={styles.customList}>
            {listData.listHeading && <p>{listData.listHeading}</p>}
            {listData.points.map((point, pointIndex) => (
              <li key={pointIndex}>
                <p className="df aifs jcfs">
                  {
                    point.includes("<break>") ? <>
                      <span className={styles.listTopic}>
                        {point.split("<break>")[0]}
                      </span> {point.split("<break>")[1]}
                    </> : point.includes(":") ? (
                      <>
                        <span className={styles.listTopic}>
                          {point.split(":")[0]}
                        </span>
                        : {point.split(":")[1]}
                      </>
                    ) : (
                      <span className={styles.listTopic}>{point}</span>
                    )}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    ));
  }
  return null;
}

export default ContentSection;
