.blogDetailPage {
  background-color: #fff;
  border-bottom: 5px solid #4A67FF;
}
.blogDetailHero {
  height: 500px;
}
.heroSectionFooter {
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  height: 120px;
  left: 0;
  bottom: 0;
  width: 100%;
  backdrop-filter: blur(94px);
  border-radius: 8px 8px 0px 0px;
}

.bannerBlogTitle {
  color: #151313;
  padding-left: 70px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
}

.BlogCategories {
  position: absolute;
  left: 70px;
  top: -12px;
  width: 100%;
  display: flex;
}
.BlogCategory {
  border-radius: 50px;
  background-color: #a2aee7;
  color: #fff;
  padding: 2px 30px;
  font-size: 15px;
  font-weight: 400;
}

.BlogCategory:not(:first-child) {
  margin-left: 12px;
}
