import { useCallback, useState, useEffect } from "react";
import { getRequest } from "../config";


const useAdCardData = (sectionNo = "D002") => {

    const [data, setData] = useState({})
    const [adId, setAdId] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await getRequest(`/get/advisor/ads/list/${sectionNo}`, {}, setData)
            setData({ ...response.body[0].advisor, isDefault: response.body[0]?.isDefault ?? false, company: { logo: response.body[0]['company']['logo'] } });
            setAdId(response.body[0]._id)
        } catch (error) {
            setData({})
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    }, [sectionNo]);

    useEffect(() => {
        if (sectionNo) {
            fetchData()
        }
    }, [sectionNo])

    return { data, adId, loading }
}

export default useAdCardData;