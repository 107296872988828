import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import OfferBanner from "../components/OfferBanner";

export default function Policies() {
	return (
		<>
			<OfferBanner>
				<span className="c1 cp px0-5rem" data-scroll-to="cookiePolicy">Cookie Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="dmcaPolicy">DMCA Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="privacyPolicy">Privacy Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="dataPrivacy/ethicsPolicy">Data Privacy & Ethics Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="aiUsePolicy">AI Use Policy</span>
			</OfferBanner>
			<div className="container mxa py5rem dg gg1rem lh3">
				<HeadingSubheadingContent id="forCompanies" wrapperClass="tac mb2rem" heading="Policies" />

				<div className="br5px bg5 w50 shadow p2rem table-of-content">
					<h3 className="mb1rem">List of Policies:</h3>
					<ul className="list-table">
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="cookiePolicy">Cookie Policy </span><i> Page 1</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="dmcaPolicy">DMCA Policy</span><i> Page 2</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="privacyPolicy">Privacy Policy</span><i> Page 3</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="dataPrivacy/ethicsPolicy">Data Privacy & Ethics Policy</span><i></i> Page 4</li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="aiUsePolicy">AI Use Policy</span><i></i> Page 5</li>
					</ul>
				</div>
				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="cookiePolicy" wrapperClass="tac" subheading="Cookie Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Use of cookies and similar technologies</p>
					<p>Diligentsia uses cookies and similar technologies, such as pixels, to provide, improve, protect, and promote our services. For the purposes of this policy we will refer to all of these technologies as “cookies.” This page includes information on the what, who, and how of these technologies in connection with the Diligentsia website and services.</p>
					<p>What are cookies?</p>
					<p>A cookie is small data file sent to your browser when you visit a website. A pixel tag ("pixel") is a small piece of code belonging to a third-party that can be embedded on websites and in emails. We use both our own cookies as well as third-party cookies. See the “How does Diligentsia use cookies” section below for further information on third-party cookies we use.</p>
					<p>We also use local storage, including HTML5 local storage and browser cache. Local storage allows us to store information locally on your browser or device to improve your experience, such as to remember your settings for whether you prefer to view your folders in a list or as thumbnails. </p>
					<p>How does Diligentsia use cookies?</p>
					<p>Diligentsia uses different categories of cookies to provide, protect, improve, and promote our website and services. Diligentsia only retains personal data for as long as is needed for the purposes for which it was collected. Information on these categories and their purposes are described below.</p>
					<p>Strictly necessary cookies</p>
					<p>These are cookies that are essential in order to operate the website and services.</p>
					<p>Category</p>
					<p>Purpose</p>
					<p>Data elements</p>
					<p>Expiration</p>
					<p>Third parties</p>
					<p>Strictly necessary</p>
					<p>Cookies that are used to operate the website and services, such as to log you in and keep your account secure.</p>
					<p>Categories of data collected for these purposes include authentication data, unique ID, browser information, and language settings.</p>
					<p>These cookies are set to expire after times ranging from 30 days to 4 years.</p>
					<p>Third party providers used include Google.</p>
					<p>Non-essential cookies</p>
					<p>These are all other cookies that are not considered strictly necessary but are used for a number of purposes when you interact with our website and services. These purposes include:</p>
					<p>Category</p>
					<p>Purpose</p>
					<p>Data elements</p>
					<p>Expiration</p>
					<p>Third parties</p>
					<p>Analytics</p>
					<p>Cookies that help us and our partners understand how our websites are engaged with, such as usage statistics, in order to improve and customize our services.</p>
					<p>Categories of data collected for these purposes include website usage data, number of visits, browser and/or device information, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Google, Salesforce, Coveo, and Tealium.</p>
					<p>General marketing and advertising</p>
					<p>Cookies that are used by Diligentsia and our partners to better understand the types of individuals who are interested in our products and services and how we can promote those services based on that information.</p>
					<p>Categories of data collected for these purposes include cross website tracking data, browser information, version data, and advertisement conversion data.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Adobe, Marketo, Yahoo, and Google.</p>
					<p>Performance and functionality</p>
					<p>Cookies which help us remember your preferences and settings in order to improve your experience when interacting with our sites and services.</p>
					<p>Categories of data collected for these purposes include website usage data, previous help center or chat history, email address, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 3 years.</p>
					<p>Third party providers used include SnapEngage.</p>
					<p>Social media advertising</p>
					<p>Cookies that are used by Diligentsia and our partners to understand the effectiveness of our advertisements on social media services. These cookies also help them understand your advertising preferences so they can more effectively show you our advertisements which are relevant to your interests.</p>
					<p>Categories of data collected for these purposes include social media account details, browser and/or device data, advertisement conversion data, website usage and/or activity data, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Facebook, Twitter, and LinkedIn.</p>
					<p>How can I control the data collected through cookies?</p>
					<p>Browser settings:</p>
					<p>You can set your browser to not accept cookies, but this may limit your ability to use our services. Due to a lack of an agreed upon standard for interpreting Do Not Track (DNT) signals, our systems currently don’t respond to DNT:1 signals from browsers visiting our websites.</p>
					<p>Cookie preferences:</p>
					<p>When you use Diligentsia’s services, you can select which cookies you consent for Diligentsia to use in by clicking Cookies & CCPA Preferences in the footer of this page under Support.</p>
					<p>Self-Regulatory:</p>
					<p>Diligentsia adheres to the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising ("Principles"). You can opt out of collection of information by third-parties that adhere to the Principles, and that we use to help us promote our services, by visiting www.aboutads.info/choices.</p>
					<p>Diligentsia also adheres to the European Interactive Digital Advertising Alliance’s self-regulatory program for data-driven advertising. You can opt out of collection of information used for online behavior advertising by third-parties that adhere to the program by visiting http://www.youronlinechoices.eu/.</p>
					<p>For more information on how to control and access your personal data, please see our Privacy Policy. If you’d like to reach out to Diligentsia regarding the use of data collected from cookies, contact our Data Protection Officer at privacy@Diligentsia.co.uk .</p>
					<p className="c4 df jcc mt1rem">Page 1</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="dmcaPolicy" wrapperClass="tac" subheading="DMCA Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Diligentsia Limited (“Diligentsia”) respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at https://www.copyright.gov/legislation/dmca.pdf, Diligentsia will respond expeditiously to claims of copyright infringement committed using the Diligentsia platform and/or the Diligentsia website (the “Site”) if such claims are reported to Diligentsia’s Designated Copyright Agent identified in the sample notice below.</p>
					<p>If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Site by completing the following DMCA Notice of Alleged Infringement and delivering it to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk . Upon receipt of Notice as described below, Diligentsia will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Site.</p>
					<p>DMCA Notice of Alleged Infringement (“Notice”):</p>
					<ol>
						<li>Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
						<li>Identify the material or link you claim is infringing (or the subject of infringing activity) and to which access is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site or the exact location where such material may be found.</li>
						<li>Provide your company affiliation (if applicable), mailing address, telephone number, and, if available, email address.</li>
						<li>Include both of the following statements in the body of the Notice:</li>
						<ol>
							<li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorised by the copyright owner, its agent or the law (e.g. as a fair use)”.</li>
							<li>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
						</ol>
						<li>Provide your full legal name and your electronic or physical signature.</li>
					</ol>
					<p>Deliver this Notice, with all items completed, to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk :</p>
					<p>Data Privacy Officer</p>
					<p>Diligentsia Limited</p>
					<p>The Old Rectory Nock Verges, Stoney Stanton,</p>
					<p>Leicestershire, LE9 4LR, UK</p>
					<p className="c4 df jcc mt1rem">Page 2</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="privacyPolicy" wrapperClass="tac" subheading="Privacy Policy" />
					<p className="pt1rem pb1rem">Effective: 30th June, 2022</p>
                    <p >At Diligentsia Limited, we are committed to protecting the privacy and security of our clients' personal data. This Data Privacy Policy outlines how we collect, use, store, and protect your information when you use our platform. By using our services, you agree to the practices described in this policy.</p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Information We Collect</p>
                    <p className="pb1rem">We only collect personal data that is necessary for us to provide our services effectively. This may include: </p>
                    <ol className="pb1rem">
                        <li>Contact information (name, email address, phone number). </li>
                        <li>Account information (username, password). </li>
                        <li>Transaction information related to your use of our platform. </li>
                    </ol>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>How We Use Your Information</p>
                    <p className="pb1rem">We use the information we collect to: </p>
                    <ol className="pb1rem">
                        <li>Provide, operate, and maintain our platform and services. </li>
                        <li>Communicate with you, including responding to inquiries and providing customer support. </li>
                        <li>Improve and personalize your experience on our platform. </li>
                        <li>Ensure compliance with legal and regulatory requirements. </li>
                    </ol>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Security </p>
                    <p className="pb1rem">Consistent with our size of business, we implement robust security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security assessments. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Sharing and Disclosure </p>
                    <p className="pb1rem">We do not share your personal data with third parties unless it is necessary to provide our services, comply with the law, or protect our rights. Where data sharing is necessary, we ensure that third parties adhere to strict data protection standards. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Retention </p>
                    <p className="pb1rem">We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law. Once the data is no longer needed, it will be securely deleted or anonymized.</p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Your Rights </p>
                    <p className="pb1rem">You have the right to access, correct, or delete your personal data that we hold. You may also object to or restrict certain types of data processing. To exercise these rights, please contact us using the information provided below. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Changes to This Policy </p>
                    <p className="pb1rem">We may update this Data Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes and post the updated policy on our website. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Contact Us </p>
                    <p className="pb1rem">If you have any questions or concerns about this Data Privacy Policy or our data practices, please contact us at: </p>
                    <ol className="pb1rem">
                        <li>Email: privacy@diligentsia.co.uk </li>
                        <li>Address: Diligentsia Limited, The Old Rectory, Nock Verges, Stoney Stanton, Leicestershire LE9 4DA, UK </li>
                    </ol>
                    <p className="pb1rem">This Data Privacy Policy is designed to give you confidence in how we handle your personal data. Your privacy is important to us, and we are committed to maintaining your trust. </p>
					<p className="c4 df jcc mt1rem">Page 3</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="dataPrivacy/ethicsPolicy" wrapperClass="tac" subheading="Data Privacy & Ethics Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Scope and Acceptance of Policy:</p>
					<p>This Data Privacy and Ethics Policy ('Policy') applies to the use of your data by Diligentsia Limited ('Company', 'we', 'us', 'our') in relation to the use of an artificial intelligence tool, Chat GPT, developed by OpenAI. Your acceptance of this Policy, along with our Terms and Conditions, is indicated by your continued use of our services and/or by expressly clicking to accept where that option is presented to you.</p>
					<p>Data Usage:</p>
					<p>We use your data for the sole purpose of enhancing the quality of reports we generate for you through the application of AI technology. Your data may be used to create comprehensive reports via Chat GPT, but will not be used for any other purpose unless we obtain your explicit permission.​</p>
					<p>Data Protection:</p>
					<p>Your data is important and private to you, and we respect that. We ensure that your data is not shared with third parties, except with Chat GPT as outlined in this policy. We do not disseminate or transfer ownership of your data. Any reports we generate using your data and AI are digitally watermarked to ensure their authenticity and traceability.</p>
					<p>Data Retention:</p>
					<p>We will not retain your data for longer than is necessary for our services or as required by law. The data you supply to us that is processed through Chat GPT is not stored or reused by the AI system.</p>
					<p>Non-disclosure of Files:</p>
					<p>The contents of any data files you provide to us will not be shared with AI systems. We will not disclose the contents of these files outside our organisation unless we have obtained your prior express permission.</p>
					<p>Your Rights:</p>
					<p>​You have the right to access, rectify, delete or restrict the use of your personal data. You can exercise these rights at any time by contacting us using the contact details provided in our Terms and Conditions.</p>
					<p>Changes to the Policy:</p>
					<p>We reserve the right to update this Policy at any time to reflect changes in our practices, service offerings, or the latest laws and regulations. Any changes will be posted on this page, and we recommend that you check this page regularly to stay informed.</p>
					<p>Contact Us:</p>
					<p>If you have any questions or require further information about our data handling practices, please don't hesitate to contact our Data Privacy Officer at dpo@diligentsia.co.uk. We're committed to working with you to obtain a fair resolution of any privacy concerns."</p>
					<p className="c4 df jcc mt1rem">Page 4</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="aiUsePolicy" wrapperClass="tac" subheading="AI Use Policy" />
					<p>Effective: 1st January 2024</p>
					<p>Introduction:</p>
					<p>At Diligentsia Limited ("we," "us," or "our"), we are committed to delivering high-quality services to our corporate customers, mainly SMEs, their professional advisors, and investors ("Customers"). We utilize generative AI technologies, including those from OpenAI, Google Bard, Microsoft Bing, and others, to assist in the creation of bespoke reports and deliver valuable insights to our Customers. We take your privacy and data security seriously, and this AI Use Policy outlines our responsible approach to using AI technologies.</p>
					<p>Data Usage and Protection:</p>
					<p>includes information provided by our Customers and may include text, documents, and other relevant data.
						Data Security: We employ robust security measures to protect your data from unauthorized access, disclosure, or alteration. We use encryption, access controls, and industry best practices to safeguard your information.
						Data Usage: We use the data provided by our Customers exclusively for the purpose of generating bespoke reports and delivering our services. We do not sell, market, or otherwise use your data for any purpose other than fulfilling our obligations to you.
						Anonymization and Aggregation: We may aggregate and anonymize data to improve our AI models, conduct research, and enhance our services. This aggregated and anonymized data cannot be traced back to individual Customers.</p>
					<p>Responsible AI Usage:</p>
					<p>AI Algorithms: We use AI algorithms to assist in the generation of reports, ensuring accuracy and efficiency. These algorithms are designed to provide valuable insights while maintaining a responsible and ethical approach.
						Transparency: We strive to be transparent about our AI usage. If you have any questions about how AI is used in our services, please feel free to contact us.</p>
					<p>Customer Rights:</p>
					<p>Access and Control: Customers have the right to access, correct, or delete their data. If you have concerns or requests regarding your data, please contact us, and we will promptly address them.
						Opt-Out: If you no longer wish to use our services, you have the right to opt-out and have your data removed from our systems.</p>
					<p>Compliance with Applicable Laws:</p>
					<p>We are committed to complying with all applicable data protection and privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>
					<p>Contact Us:</p>
					<p className="df aic">If you have any questions, concerns, or requests related to our AI Use Policy or the use of AI in our services, please contact us at: <a className="db p0-5rem td250 c4-hover" href="mailto: support@diligentsia.co.uk">support@diligentsia.co.uk</a>.</p>
					<p>Changes to the AI Use Policy:</p>
					<p>We may update this AI Use Policy from time to time to reflect changes in our business practices or legal requirements. The most current version will be posted on our website, and we will notify Customers of any significant changes.</p>
					<p>By using our services, you agree to this AI Use Policy and our Terms and Conditions.</p>
					<p>Effective Date:</p>
					<p>This AI Use Policy is effective as of 1st January 2024.</p>
					<p className="c4 df jcc mt1rem">Page 5</p>
				</div>
			</div>
		</>
	);
}
