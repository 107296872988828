import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getModalReducer(modal = { open: '' }) {
	const modalSlice = createSlice({
		name: "modal",
		initialState: {
			...modal,
			resetTab: false
		},
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			},
			resetTab: (state, action) => {
				return { ...state, resetTab: action.payload };
			},
		}
	});

	return modalSlice.reducer;
}

export const toggleModal = createAction("modal/update");
export const resetTabModal = createAction("modal/resetTab");

