import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getFilesAllocationReducer() {
    const filesAllocation = createSlice({
        name: "FILES_ALLOCATIONS",
        initialState: {
            fileReport: {},
            company: null,
            currentScore: 0,
            reportHistories: [],
            profileClass: null,
            tab: 'repository',
            logList: [],
            rModalData: { key: '', from: '', index: 0, fileName: '' },
            openRModal: false,
            targetSection: {},
            processing: false
        },
        reducers: {
            RESET_FILE_ALLOCATION: () => {
                return {
                    fileReport: {},
                    company: null,
                    currentScore: 0,
                    reportHistories: [],
                    profileClass: null,
                    tab: 'repository',
                    logList: [],
                    rModalData: { key: '', from: '', index: 0, fileName: '' },
                    openRModal: false,
                    targetSection: {},
                    processing: false
                };
            },
            UPDATE: (state, action) => {
                return { ...state, ...action.payload };
            },
            SET_LOG_LIST: (state, action) => {
                return {
                    ...state,
                    logList: action.payload
                };
            },
            SET_FILE_REPORT: (state, action) => {
                return { ...state, fileReport: action.payload };
            },
            SET_CURRENT_SCORE: (state, action) => {
                return { ...state, currentScore: action.payload };
            },
            SET_R_MODAL_DATA: (state, action) => {
                return { ...state, rModalData: action.payload };
            },
            SET_OPEN_R_MODAL: (state, action) => {
                return { ...state, openRModal: action.payload };
            },
            SET_TARGET_SELECTION: (state, action) => {
                return { ...state, targetSection: action.payload };
            },
            SET_CHANGE_IMPORT_RESULT: (state, action) => {
                const { index, section, originalSection } = action.payload;
                return {
                    ...state,
                    fileReport: {
                        ...state.fileReport,
                        importResult: {
                            ...state.fileReport.importResult,
                            [index]: {
                                ...state.fileReport.importResult[index],
                                section: state.fileReport.importResult[index].section.includes(section) ? [...state.fileReport.importResult[index].section] : [
                                    ...state.fileReport.importResult[index].section.slice(0, index),
                                    section,
                                    ...state.fileReport.importResult[index].section.slice(index + 1),
                                ],
                                originalSection: state.fileReport.importResult[index].originalSection.includes(originalSection) ? [...state.fileReport.importResult[index].originalSection] : [
                                    ...state.fileReport.importResult[index].originalSection.slice(0, index),
                                    originalSection,
                                    ...state.fileReport.importResult[index].originalSection.slice(index + 1),
                                ]
                            }
                        }

                    }
                };
            },
            SET_DELETE_IMPORT_RESULT: (state, action) => {
                const { index, qNo } = action.payload;
                let { [index]: removed, ...newImportResult } = state.fileReport.importResult;
                if (removed.section.length > 1) {
                    newImportResult = {
                        ...state.fileReport.importResult,
                        [index]: {
                            ...state.fileReport.importResult[index],
                            section: state.fileReport.importResult[index].section.filter((item) => item !== qNo),
                            originalSection: state.fileReport.importResult[index].originalSection.filter((item) => item !== qNo),
                        }
                    }
                }
                return {
                    ...state,
                    fileReport: {
                        ...state.fileReport,
                        importResult: newImportResult
                    }
                };
            },
            SET_MATCH_FILE_PROCESSING: (state, action) => {
                return { ...state, processing: action.payload };
            }
        }
    });

    return filesAllocation.reducer;
}

export const updateFileAllocation = createAction("FILES_ALLOCATIONS/UPDATE");
export const setLogList = createAction("FILES_ALLOCATIONS/SET_LOG_LIST");
export const setFileReport = createAction("FILES_ALLOCATIONS/SET_FILE_REPORT");
export const setCurrentScore = createAction("FILES_ALLOCATIONS/SET_CURRENT_SCORE");
export const resetFileAllocation = createAction("FILES_ALLOCATIONS/RESET_FILE_ALLOCATION");
export const setRModalData = createAction("FILES_ALLOCATIONS/SET_R_MODAL_DATA");
export const setOpenRModal = createAction("FILES_ALLOCATIONS/SET_OPEN_R_MODAL");
export const setTargetSection = createAction("FILES_ALLOCATIONS/SET_TARGET_SELECTION");
export const setChangeImportResult = createAction("FILES_ALLOCATIONS/SET_CHANGE_IMPORT_RESULT");
export const setDeleteImportResult = createAction("FILES_ALLOCATIONS/SET_DELETE_IMPORT_RESULT");
export const setMatchFileProcessing = createAction("FILES_ALLOCATIONS/SET_MATCH_FILE_PROCESSING");

