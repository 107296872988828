import React from "react";
import styles from "./BlogPost.module.css";
import styles1 from "../../../pages/blogs/BlogPage.module.css";
import { blogPosts } from "../data/blogData";
import BlogCard from "../BlogCard";

function LatestBlogPosts() {

  const randomBlogs = [...blogPosts]

  return (
    <section className={styles.latestBlogPosts}>
      <h2 className={styles.sectionTitle}>Latest Blog Posts</h2>
      <div className={`${styles1.BlogGrid} py2rem`}>
        {randomBlogs.sort(() => Math.random() - 0.5).slice(0, 3).map((post, index) => (
          <BlogCard key={`BlogLatestCard-${index}`} post={post} />
        ))}
      </div>
    </section>
  );
}

export default LatestBlogPosts;
