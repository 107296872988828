import { createAction, createSlice } from "@reduxjs/toolkit";
import { blogPosts, featuredPost } from "../components/blogs/data/blogData";

export default function getBlogReducer() {
    const blogSlice = createSlice({
        name: "BLOGS",
        initialState: {
            q: '',
            tab: 'All',
            page: 1,
            pageSize: 9,
            filtered: false,
            rawData: [...blogPosts],
            blogPosts,
            featuredPost
        },
        reducers: {
            FILTER_BLOG: (state, action) => {
                return {
                    ...state,
                    ...action.payload
                };
            }
        }
    });

    return blogSlice.reducer;
}

export const filterBlogs = createAction("BLOGS/FILTER_BLOG");

