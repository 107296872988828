import React from "react";
import BlogSection from "./BlogSection";
import DefaultCard from "./Card/DefaultCard";
import CardWrapper from "./Card/CardWrapper";
import SecondaryCard from "./Card/SecondaryCard";

function BlogContent({ sec: { data, type, cardStyle, bgImage = "" }, styles }) {
  if (type === "content") {
    return <BlogSection styles={styles} data={data} />;
  } else if (type === "card") {
    return (
      <div className={`${styles.BlogCard} cardBlog`}>
        <CardWrapper bgImage={bgImage ?? ""}>
          {!cardStyle || cardStyle === "default" ? (
            <DefaultCard
              title={data.title}
              features={data.points}
              titlePrefixIcon={data.titlePrefixIcon}
              imageSrc={data.image}
              maxWidth={data.maxWidth ?? "auto"}
            />
          ) : (
            <SecondaryCard
              title={data.title}
              features={data?.points ?? []}
              subTitle={data.subTitle}
              imageSrc={data.image}
              maxWidth={data.maxWidth ?? "auto"}
            />
          )}
        </CardWrapper>
      </div>
    );
  } else if (type === "image") {
    return <img src={data} alt="diligentsia" className="w100 p2rem bsbb" />;
  }

  return null;
}

export default BlogContent;
