// BlogSection.js
import React from "react";
import ContentSection from "./ContentSection";

function BlogSection({ data, styles }) {
  return (
    <div className={styles.blogText}>
      {Object.entries(data).map(([key, value], index) => {
        return (
          <ContentSection
            key={index}
            keyName={key}
            value={value}
            styles={styles}
          />
        );
      })}
    </div>
  );
}

export default BlogSection;
