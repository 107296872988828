import React from "react"
import AnimatedPage from "../../components/AnimatedPage";
import SmoothScrollingButton from "../../components/SmoothScrollingButton";
import CustomTab from "../../components/termsAndConditions/CustomTab";

const Privacy = () => {

    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="privacy-policy" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box">
                    <p className="pt1rem pb1rem">Effective: 30th June, 2022</p>
                    <p >At Diligentsia Limited, we are committed to protecting the privacy and security of our clients' personal data. This Data Privacy Policy outlines how we collect, use, store, and protect your information when you use our platform. By using our services, you agree to the practices described in this policy.</p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Information We Collect</p>
                    <p className="pb1rem">We only collect personal data that is necessary for us to provide our services effectively. This may include: </p>
                    <ol className="pb1rem">
                        <li>Contact information (name, email address, phone number). </li>
                        <li>Account information (username, password). </li>
                        <li>Transaction information related to your use of our platform. </li>
                    </ol>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>How We Use Your Information</p>
                    <p className="pb1rem">We use the information we collect to: </p>
                    <ol className="pb1rem">
                        <li>Provide, operate, and maintain our platform and services. </li>
                        <li>Communicate with you, including responding to inquiries and providing customer support. </li>
                        <li>Improve and personalize your experience on our platform. </li>
                        <li>Ensure compliance with legal and regulatory requirements. </li>
                    </ol>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Security </p>
                    <p className="pb1rem">Consistent with our size of business, we implement robust security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security assessments. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Sharing and Disclosure </p>
                    <p className="pb1rem">We do not share your personal data with third parties unless it is necessary to provide our services, comply with the law, or protect our rights. Where data sharing is necessary, we ensure that third parties adhere to strict data protection standards. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Data Retention </p>
                    <p className="pb1rem">We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law. Once the data is no longer needed, it will be securely deleted or anonymized.</p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Your Rights </p>
                    <p className="pb1rem">You have the right to access, correct, or delete your personal data that we hold. You may also object to or restrict certain types of data processing. To exercise these rights, please contact us using the information provided below. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Changes to This Policy </p>
                    <p className="pb1rem">We may update this Data Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes and post the updated policy on our website. </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Contact Us </p>
                    <p className="pb1rem">If you have any questions or concerns about this Data Privacy Policy or our data practices, please contact us at: </p>
                    <ol className="pb1rem">
                        <li>Email: privacy@diligentsia.co.uk </li>
                        <li>Address: Diligentsia Limited, The Old Rectory, Nock Verges, Stoney Stanton, Leicestershire LE9 4DA, UK </li>
                    </ol>
                    <p className="pb1rem">This Data Privacy Policy is designed to give you confidence in how we handle your personal data. Your privacy is important to us, and we are committed to maintaining your trust. </p>
                </div>
            </div >
        </AnimatedPage >
    )

}
export default Privacy;