import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setChangeImportResult, setCurrentScore, setFileReport, setOpenRModal, setTargetSection } from '../../../reducers/filesAllocationReducer'
import { Modal as RModal } from 'rsuite';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { postRequest } from '../../../config';

const OpenRModalComponent = () => {

    const dispatch = useDispatch()
    const { company, tab, rModalData, openRModal, targetSection, fileReport } = useSelector(state => state.fileAllocation)
    const { selectedCompany } = useSelector(state => state.company)
    const Chevron = ({ className = "" }) => {
        return (
            <svg className={className} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
        )
    };

    function isSubarray(subarray, array) {
        const sortedSubarray = subarray.slice().sort();
        const sortedArray = array.slice().sort();

        let i = 0;
        return sortedArray.some((element) => {
            if (element === sortedSubarray[i]) {
                i++;
            }
            return i === sortedSubarray.length;
        });
    };

    const calcAccuracyScore = () => {
        let allocated = 0;

        for (const key of Object.keys(fileReport.importResult)) {
            const currentSection = fileReport.importResult[key]['section'];
            const oldSection = fileReport.importResult[key]['originalSection'];

            if (oldSection.includes("DXXX")) continue;

            if (isSubarray(oldSection, currentSection)) {
                allocated++;
            }
        }
        const totalCount = Object.keys(fileReport.importResult).length;
        dispatch(setCurrentScore(!totalCount ? 0 : Math.round(allocated / totalCount * 100)))
        return !totalCount ? 0 : Math.round(allocated / totalCount * 100);
    };

    const selectTargetSection = async (fileKey, qNo, name, from, index) => {
        if (from && from == 'fileReport') {
            if (fileReport.importResult[fileKey]['section'][index] != qNo) {
                const score = calcAccuracyScore();
                dispatch(
                    setFileReport({
                        ...fileReport,
                        importResult: {
                            ...fileReport.importResult,
                            [fileKey]: {
                                ...fileReport.importResult[fileKey],
                                section: [
                                    ...(fileReport.importResult[fileKey].section || []).slice(0, index),
                                    qNo,
                                    ...(fileReport.importResult[fileKey].section || []).slice(index + 1)
                                ]
                            }
                        },
                        currentScore: score
                    }
                    ))
                const res = await postRequest(`/company/allocateFile`, { companyId: company._id, fileReportId: fileReport['_id'], fileKey, index, qNo, score, file: fileReport.importResult[fileKey]['file'], isAdd: false });
                if (!res) return;
                if (res['code'] === 200) {
                    dispatch(setChangeImportResult({ index: fileKey, section: qNo, originalSection: qNo }));
                    dispatch(setCurrentScore(score))
                }
            }
        } else {
            let curSection = { ...targetSection }
            curSection[fileKey] = { qNo: qNo, name: name };
            dispatch(setTargetSection(curSection));
        }
        dispatch(setOpenRModal(false))
    };

    const getSectionDropDown = (section, fileKey, from, index) => {
        const qNo = section.sectionNo || section.no;
        const name = section.sectionName || section.name;

        if (section.subFolders) {
            return <>
                <AccordionItem key={qNo}>
                    {({ open }) => (
                        <>
                            <AccordionHeader className={'w100 df aic dropdown-section-header jcsb cp bg5 mb0-5rem'}>
                                <h3 style={{ color: '#002d63' }}>{qNo}. {name}</h3>
                                <Chevron
                                    className={`w1rem h1rem td300 ml0-5rem ${!open ? 'tr90deg' : 'tr270deg'}`}
                                />
                            </AccordionHeader>

                            <AccordionBody>
                                <div className={`pl1rem`}>
                                    {Object.keys(section.subFolders).map(key => {
                                        return getSectionDropDown(
                                            section.subFolders[key],
                                            fileKey,
                                            from,
                                            index
                                        )
                                    })}
                                </div>
                            </AccordionBody>
                        </>
                    )}
                </AccordionItem>
            </>
        } else {
            return <>
                <div className="rs-dropdown-item" onClick={() => selectTargetSection(fileKey, qNo, name, from, index)}>
                    {qNo}. {name}
                </div>
            </>
        }
    };

    const getModuleDropdown = (module, key, from, index) => {
        if (module?.sections?.length == 1) {
            const singleSection = module?.sections[0];
            if (singleSection.subFolders) {
                return <>
                    {Object.values(singleSection.subFolders)
                        .map(section => {
                            return getSectionDropDown(section, key, from, index);
                        })
                    }
                </>
            }
        }

        return <>
            {module?.sections && Object.values(module?.sections).map(section => {
                return (
                    getSectionDropDown(section, key, from, index)
                )
            })}
        </>
    };

    if ((company && company['modules']) || (selectedCompany && selectedCompany['modules'])) {
        return (
            <>
                <RModal overflow={true} open={openRModal} onClose={() => dispatch(setOpenRModal(false))} style={{ zIndex: '2000' }}>
                    <RModal.Header>
                        <RModal.Title>{rModalData.fileName}</RModal.Title>
                    </RModal.Header>
                    <RModal.Body>
                        <Accordion>
                            {(() => {
                                let module = company?.modules[tab] ?? selectedCompany?.modules[tab];
                                if (module) module = JSON.parse(JSON.stringify(module));
                                return getModuleDropdown(module, rModalData.key, rModalData.from, rModalData.index);
                            })()}
                        </Accordion>
                    </RModal.Body>
                </RModal>
            </>
        )
    } else {
        return (<></>)
    }
}

export default OpenRModalComponent