import React from "react";
import BlogContent from "./BlogContent";
import LatestBlogPosts from "./LatestBlogPosts";
import AuthorInfo from "../AuthorInfo";
import CardWrapper from "./BlogContent/Card/CardWrapper";
import AboutDiligentsiaSection from "./AboutDiligentsiaSection";

import styles from "./BlogPost.module.css";
import BookACallButtons from "./BookACallButtons";

function BlogPost({ post }) {
  const { postData } = post;

  return (
    <article className={styles.blogPost}>
      <main className={styles.mainContent}>
        <section className={styles.blogContent}>
          <AuthorInfo post={post} />
          {Object.values(postData).map((sec, secIndex) => {
            return (
              <BlogContent
                key={`${secIndex}-${post.slug}`}
                styles={styles}
                sec={sec}
              />
            );
          })}
          <CardWrapper>
            <AboutDiligentsiaSection />
          </CardWrapper>
          <BookACallButtons />
          <LatestBlogPosts />
        </section>
      </main>
    </article>
  );
}

export default BlogPost;
