.blogPage {
  max-width: none !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 5px solid #4A67FF;
}

.userProfile {
  width: fit-content;
  border-radius: 15px;
}

.blogHero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 0;
  min-height: 400px;
}

.blogTitle {
  color: #151313;
  margin: 20px 0px;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  font-family: "Poppins";
}

.featuredPost .blogTitle {
  font-size: 30px;
}

.searchForm {
  display: flex;
  width: 505px;
  max-width: 100%;
  border-radius: 60px;
  background-color: #fff;
  border: 1px solid #dbdfee;
  overflow: hidden;
}

.searchInput {
  flex-grow: 1;
  border: none;
  padding: 16px;
  font: 400 20px/1.4 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.searchButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
}

.searchIcon {
  width: 46px;
}

.categoryNav {
  margin-top: 36px;
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  list-style-type: none;
  padding: 0;
}

.categoryLink {
  color: #151313;
  text-align: center;
  font: 400 21px Poppins, sans-serif;
  text-decoration: none;
}

.categoryLink.active {
  color: #2947ef;
  font-weight: 600;
  position: relative;
}

.categoryLink.active::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 53px;
  height: 2px;
  background-color: #4a67ff;
  border-radius: 20px;
}

.blogGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;
  max-width: 1680px;
  margin: 0 auto;
}

.featuredPost {
  width: 100%;
}

.showMoreBtn {
  background-color: #060606;
  padding: 8px 40px;
  border-radius: 60px;
  color: #fff;
}

.regularPosts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blogCard {
  background-color: #fff;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.blogCard.featured {
  display: grid;
  grid-template-columns: 40% 60%; /* 40% width for the first column, 60% for the second */
  gap: 40px;
}

.blogImage {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.blogContent {
  padding: 20px 20px 20px 0px;
}

.featuredPost .blogContent {
  padding-left: 20px;
}

.blogCategory {
  color: #4a67ff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.99px;
  font-family: "Poppins";
}

.blogExcerpt {
  color: #505050;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 20px;
  font-family: "Poppins";
}

.blogFooter {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative!important;
}

.FeaturedBadge {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 50px;
  background-color: #627bbf;
  color: #fff;
  padding: 2px 30px;
  font-size: 15px;
  font-weight: 400;
}

.BlogGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.authorAvatar {
  width: 39px;
  height: 39px;
  border-radius: 50%;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}

.authorName {
  color: #4c6b92;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
}

.authorRole {
  margin-left: 4px;
}
.authorRole,
.postDate {
  color: #4c6b92;
  font-size: 12px;
  font-family: "Poppins";
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .blogPage {
    margin-top: 40px;
  }

  .blogTitle {
    font-size: 40px;
  }

  .searchForm,
  .blogGrid,
  .footerContent {
    max-width: 100%;
  }

  .blogCard.featured {
    flex-direction: column;
  }
}
