// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import AddOutlineSvg from '../icons/action/AddOutline';
var AddOutline = createSvgIcon({
    as: AddOutlineSvg,
    ariaLabel: 'add outline',
    category: 'action',
    displayName: 'AddOutline'
});
export default AddOutline;
