import React, { useState } from 'react'
import moment from 'moment'
import TrashIcon from '@rsuite/icons/Trash';
import Modal from '@mui/material/Modal';
import EditIcon from '@rsuite/icons/Edit';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentScore, setLogList, setRModalData, setOpenRModal, setDeleteImportResult, setFileReport, setMatchFileProcessing } from '../../../reducers/filesAllocationReducer';
import { getRequest, postRequest, showMessage } from '../../../config';
import { toggleModal } from '../../../reducers/modalReducer';
import { useLocation } from "react-router-dom";
import { setCompany } from '../../../reducers/companyReducer';

const ImportSummaryModal = () => {

    // hooks and vars
    const dispatch = useDispatch()
    const location = useLocation();
    const tabName = window.location.hash.replace('#', '');
    let modal = useSelector(state => state.modal);
    const { fileReport, company, currentScore, reportHistories, profileClass, tab } = useSelector(state => state.fileAllocation)
    const [moduleContent] = useState({
        'kpi': {
            heading: 'KPI',
            aiEnhanced: true,
            uploadFiles: false,
            subheading: 'Key Performance Indicators'
        }, 'repository': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Repository',
            subheading: 'Securely store all your important documents here'
        }, 'business-audit-investment': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'business-audit-growth': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'business-audit-exit': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'directors-questionnaire': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Directors Questionnaire',
            subheading: ''
        }, 'two-minute-test-exit': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Exit Ready Test',
            subheading: 'See How Exit Ready You Are'
        }, 'two-minute-test-growth': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Growth Ready Test',
            subheading: 'See How Growth Ready You Are'
        }, 'esg': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Environmental, Social & Governmental',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'software-development': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Software Development',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'warranty-disclosure': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Warranty Disclosure Schedule',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'two-minute-test-investment': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Investment Ready Test',
            subheading: 'How Investment Ready Are You'
        }, 'unaudited-certification': {
            sections: [],
            heading: 'Certification',
            subheading: 'This is your current certification',
        }, 'investor-lead': {
            uploadFiles: true,
            heading: 'Investor Lead Introduction',
            subheading: 'Securely store all your important documents here'
        }
    });

    const onClickFileImportAuditLog = async (fileReportId) => {
        const res = await getRequest(`/company/getFileReportLogs/${fileReportId}`);
        setLogList(res['body']);
        dispatch(toggleModal({ open: 'fileReportAuditLog' }))
    };

    const onClickReallocationComplete = async () => {
        const fileReportData = {
            ...fileReport,
            status: 'Complete',
            currentScore: currentScore
        }
        dispatch(setFileReport(fileReportData))
        const resp = await postRequest(`/company/allocateFiles`, { companyId: company._id, tab, fileReport: fileReportData }, true);
        if (!resp) return;
        const updatedReport = resp.body['report'];

        for (let i = 0; i < reportHistories.length; i++) {
            if (reportHistories[i]['_id'] == updatedReport['_id']) {
                reportHistories[i] = updatedReport;
                break;
            }
        }
        showMessage(resp['message']);
        dispatch(toggleModal({ open: '' }))

        if (location.pathname.includes(company._id)) {
            const sanitiseTab = t => t.replace(/(-sync)?-report$/, "");
            let moduleName = tabName && sanitiseTab(tabName);
            const res = await getRequest(`/company/get/${company._id}?moduleName=${[moduleName]}`);
            if (res['code'] == 200) {
                res['body']['modules']['kpi'] = { type: "kpi", sections: [] };
                for (let module of Object.values(res['body']['modules'])) {
                    let content = module['type'].includes('directors-questionnaire') ? moduleContent['directors-questionnaire'] : moduleContent[module['type']]
                    if (content) {
                        module['heading'] = module['director'] ? `${content['heading']} - ${module['director']['name']} (${module['director']['designation']})` : content['heading'];
                        module['aiEnhanced'] = content['aiEnhanced'];
                        module['subheading'] = content['subHeading'];
                        module['uploadFiles'] = content['uploadFiles'];
                    }
                }
                res['body']['modules']['unaudited-certification'] = moduleContent['unaudited-certification'];
                // if (company?.['modules']) res['body']['modules'] = company['modules'];
                dispatch(setCompany(res['body']))
            }
        }

        dispatch(setMatchFileProcessing(false))

    };

    const getSectionNameFromSection = (section, qNo) => {
        if (section.sectionNo == qNo || section.no == qNo)
            return section.sectionName || section.name;

        if (section.subFolders) {
            for (const subFolder of Object.values(section.subFolders)) {
                let result = getSectionNameFromSection(subFolder, qNo);
                if (result != "")
                    return result;
            }
        }

        return "";
    };

    const getSectionName = (module, qNo) => {
        if (qNo == "DXXX" || qNo == "SXXX") return "Unallocated Bin";

        const sections = module?.sections;
        for (const section of sections) {
            const sectionName = getSectionNameFromSection(section, qNo);
            if (sectionName != "")
                return sectionName;
        }

        return "Unknown";
    };

    const handleEditDispatch = (action) => {
        dispatch(setRModalData({ ...action }))
        dispatch(setOpenRModal(true))
    }

    function isSubarray(subarray, array) {
        const sortedSubarray = subarray.slice().sort();
        const sortedArray = array.slice().sort();

        let i = 0;
        return sortedArray.some((element) => {
            if (element === sortedSubarray[i]) {
                i++;
            }
            return i === sortedSubarray.length;
        });
    };

    const calcAccuracyScore = (count) => {
        let allocated = 0;
        for (const key of Object.keys(fileReport.importResult)) {
            const currentSection = fileReport.importResult[key]['section'];
            const oldSection = fileReport.importResult[key]['originalSection'];

            if (oldSection.includes("DXXX") || oldSection.includes("SXXX")) continue;

            if (isSubarray(oldSection, currentSection)) {
                allocated++;
            }
        }
        const totalCount = count ?? Object.keys(fileReport.importResult).length;
        dispatch(setCurrentScore(!totalCount ? 0 : Math.round(allocated / totalCount * 100)))
        return !totalCount ? 0 : Math.round(allocated / totalCount * 100);
    };

    const removeSectionFromImport = async (fileKey, index) => {
        let sections = [...fileReport.importResult[fileKey]['section']];
        const qNo = sections[index];
        sections.splice(index, 1);
        const file = fileReport.importResult[fileKey]['file'];
        if (sections.length == 0) {
            delete fileReport.importResult[fileKey];
        }

        const score = calcAccuracyScore(Object.keys(fileReport.importResult).length)
        dispatch(setFileReport(fileReport))

        const res = await postRequest(`/company/deleteSection`, {
            companyId: company._id, fileReportId: fileReport['_id'], fileKey, index, qNo, score, file
        });
        if (!res) return;
        if (res['code'] === 200) dispatch(setDeleteImportResult({ index: fileKey, qNo }));
    };

    const addNewSectionToFile = async (fileKey, oldIndex) => {
        const newSection = fileReport.importResult[fileKey]['section'][oldIndex];
        let index = 0;

        if (newSection == '') {
            return;
        }

        let newArray = {
            ...fileReport,
            importResult: {
                ...fileReport.importResult,
                [fileKey]: {
                    ...fileReport.importResult[fileKey],
                    section: [...(fileReport.importResult[fileKey].section || [])],
                    originalSection: [...(fileReport.importResult[fileKey].originalSection || [])]
                }
            }
        };

        if (Array.isArray(fileReport.importResult[fileKey]['section'])) {
            newArray.importResult[fileKey]['section'].push(newSection)
            index = newArray.importResult[fileKey]['section'].length - 1;
        } else {
            newArray.importResult[fileKey]['section'] = [newSection];
        }

        const newScore = calcAccuracyScore()
        const res = await postRequest(`/company/allocateFile`, {
            companyId: company._id, fileReportId: newArray['_id'], fileKey, index, qNo: newSection, score: newScore, file: newArray.importResult[fileKey]['file'],
            isAdd: true,
        });
        dispatch(setFileReport({ ...newArray, currentScore: newScore }))
        if (!res) return;
    };

    const getImportResultEntryHTML = key => {
        const fileName = fileReport.importResult[key].file['originalname'];
        const section = fileReport.importResult[key]['section'];

        let module = { ...company.modules[tab] };

        if (module) module = JSON.parse(JSON.stringify(module));

        if (Array.isArray(section)) {
            return <>
                {
                    section.map((qNo, index) => {
                        const sectionName = getSectionName(module, qNo);
                        return <>
                            <tr className={`${index == section.length - 1 ? 'fis-tr' : ''}`}>
                                <td className={`tal pl3rem fis-td mx1rem fs1rem fw600 bg1 ${index != 0 ? 'vh' : ''} ${qNo == "DXXX" ? 'red' : (fileReport['status'] == 'In Progress' ? 'orange' : 'green')}`}>
                                    {fileName}
                                </td>
                                <td className={`tal pl3rem mx1rem fs1rem fw600 bg1 fis-td`}>
                                    {/* <span className={`mx1rem fs1rem fw600 bg1 tal`}>{sectionItem}</span> */}
                                    {qNo}. {sectionName}
                                </td>
                                {fileReport['status'] == 'In Progress' && (
                                    <td className={`mx1rem fs1rem fw600 bg1 tal fis-td fis-td-action`}>
                                        <div className="df fdr jcc aic">
                                            <EditIcon className="cp f3" color="#002d63" data-tooltip={"Change Section"} onClick={() => handleEditDispatch({ key: key, from: 'fileReport', index: index, fileName: fileName })} />
                                            <TrashIcon data-tooltip={"Delete"} onClick={() => removeSectionFromImport(key, index)} className="cp f9 ml1rem" />
                                            {qNo != "DXXX" && (
                                                <AddOutlineIcon data-tooltip={"Add"} onClick={() => addNewSectionToFile(key, index)} className={`cp f3 ml1rem ${index != 0 ? 'vh' : ''}`} />
                                            )}

                                        </div>

                                    </td>
                                )}
                            </tr>
                        </>
                    })
                }
            </>

        } else {
            const sectionName = getSectionName(module, section);
            return <>
                <tr className={`${index == section.length - 1 ? 'fis-tr' : ''}`}>
                    <td className={`tal pl3rem fis-td mx1rem fs1rem fw600 bg1`}>
                        {fileName}
                    </td>
                    <td className={`tal pl3rem  fis-td mx1rem fs1rem fw600 bg1 fis-td`}>
                        {/* <span className={`mx1rem fs1rem fw600 bg1 tal`}>{sectionItem}</span> */}
                        {section}. {sectionName}
                    </td>
                    <td className={`mx1rem fs1rem fw600 bg1 tal fis-td fis-td-action`}>
                        <EditIcon onClick={handleEditDispatch({ key: key, fileName: fileName })} />
                    </td>
                </tr>
            </>
        }
    };

    if (fileReport && company && modal['open'] == 'importSummary') {
        return (
            <>
                <Modal open={modal['open'] == 'importSummary'} onClose={onClickReallocationComplete} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                    <div className="pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw1000px br5px pr">
                        <span className="pa t0 l0 m1rem ttu c7 fs1-5rem fw500">{company.subscription}</span>
                        <img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
                        <button className="close-popup" onClick={onClickReallocationComplete}>
                            <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg>
                        </button>
                        <div className='df jcc'>
                            <h1 className="text-gradient fw600 tac mb1rem fs1-5em">{company.cName}</h1>
                        </div>
                        <h1 className="w100 fw600 tac mb1rem fs1rem tae">Allocation accuracy {currentScore}%</h1>

                        <div className='df jcc'>
                            <h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia Filing Assistant</h2>
                            <button onClick={() => onClickFileImportAuditLog(fileReport['_id'])} className='pa r0 cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem mr3rem fw500'>Audit Log</button>
                        </div>
                        <h3 className="">{fileReport.importResult && (
                            <>
                                [{Object.keys(fileReport.importResult).length}]imported files = [{Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX") && !element.includes("SXXX"))).length}] allocated files +
                                [{Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX") || element.includes("SXXX"))).length}] unallcated bin files
                            </>
                        )}
                        </h3>
                        <h3 className="w100 mb2rem">
                            {fileReport?.['lastAllocatedBy'] ? (
                                <>
                                    Reallocated by&nbsp;
                                    <span className={`w2rem h2rem jcc aic br100 bw3px bss dib wsn`}>
                                        {fileReport?.['lastAllocatedBy']?.['profile']
                                            ? <img src={fileReport?.['lastAllocatedBy']?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={fileReport?.['lastAllocatedBy']?.['firstName'] ? (fileReport?.['lastAllocatedBy']?.['firstName'] + " " + fileReport?.['lastAllocatedBy']?.['lastName']) : "?"} />
                                            : <span className={`wsn w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={fileReport?.['lastAllocatedBy']?.['firstName'] ? (fileReport?.['lastAllocatedBy']?.['firstName'] + " " + fileReport?.['lastAllocatedBy']?.['lastName']) : "?"}>{fileReport?.['lastAllocatedBy']?.['firstName'] ? (fileReport?.['lastAllocatedBy']?.['firstName'][0] + fileReport?.['lastAllocatedBy']?.['lastName'][0]) : "?"}</span>
                                        }
                                    </span>
                                    &nbsp;{moment(fileReport['lastAllocatedOn']).format('lll')}
                                </>
                            ) : (
                                <>
                                    Imported by&nbsp;
                                    <span className={`w2rem h2rem jcc aic br100 bw3px bss dib wsn`}>
                                        {fileReport?.['importBy']?.['profile']
                                            ? <img src={fileReport?.['importBy']?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={fileReport?.['importBy']?.['firstName'] ? (fileReport?.['importBy']?.['firstName'] + " " + (fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'] : "")) : "?"} />
                                            : <span className={`wsn w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={fileReport?.['importBy']?.['firstName'] ? (fileReport?.['importBy']?.['firstName'] + " " + (fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'] : "")) : "?"}>{fileReport?.['importBy']?.['firstName'] ? (fileReport?.['importBy']?.['firstName'][0] + (fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'][0] : "")) : "?"}</span>
                                        }
                                    </span>
                                    &nbsp;{moment(fileReport['importDate']).format('lll')}
                                </>
                            )}

                        </h3>
                        <div className="dg gg0-5rem mb2rem fs1rem mah400px oya">
                            <table className="fis bcc">
                                <thead>
                                    <tr>
                                        <th className="fis-th1 w40 tal pl3rem">Imported File</th>
                                        <th className="fis-th1 w60 tal pl3rem">File Allocation Location</th>
                                        {fileReport['status'] == 'In Progress' && (
                                            <th className="fis-th fis-th-action">Change Allocation?</th>
                                        )}
                                    </tr>
                                </thead>
                                {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).length > 0 && (
                                    <tbody>
                                        {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).map(getImportResultEntryHTML)}
                                    </tbody>
                                )}
                            </table>
                            {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).length == 0 && (
                                <span className="fs1-25rem tac">No files are allocated</span>
                            )}
                            {fileReport['status'] == 'In Progress' ? (
                                <span className="tac fs1-5rem fw600 mt1rem">Files you need to reallocate</span>
                            ) : (
                                <span className="tac fs1-5rem fw600 mt1rem">Files you need to reallocate in Unallocated Bin</span>
                            )}

                            {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX"))).length > 0 && (
                                <table className="fis bcc">
                                    <thead>
                                        <tr>
                                            <th className="fis-th w40 bbn"></th>
                                            <th className="fis-th w60 bbn"></th>
                                            {fileReport['status'] == 'In Progress' && (
                                                <th className="fis-th fis-th-action bbn"></th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX"))).map(getImportResultEntryHTML)}
                                    </tbody>
                                </table>
                            )}
                            {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX"))).length == 0 && (
                                <span className="red fs1-25rem tac">No files need to reallocate</span>
                            )}
                        </div>
                        <div className="df w100 fdr jcc ">
                            {modal['from'] == 'reportHistory' && (
                                <button onClick={() => dispatch(toggleModal({ open: 'fileReportHistory' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttu bsbb tac mb1rem df jcc aic mr1rem">Back to List</button>
                            )}
                            {fileReport['status'] == 'In Progress' && fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).length > 0 && (
                                <button onClick={onClickReallocationComplete} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttu bsbb tac mb1rem df jcc aic">Reallocation Complete</button>
                            )}
                        </div>
                    </div>
                </Modal>
            </>
        )
    } else {
        return (<></>)
    }
}

export default ImportSummaryModal