// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import EditSvg from '../icons/action/Edit';
var Edit = createSvgIcon({
    as: EditSvg,
    ariaLabel: 'edit',
    category: 'action',
    displayName: 'Edit'
});
export default Edit;
