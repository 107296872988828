import { createSlice, createAction } from "@reduxjs/toolkit";

export default function getCompanyReducer(company = {}) {
	const companySlice = createSlice({
		name: "company",
		initialState: {
			company,
			selectedCompany: {}
		},
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			},
			selectedCompany: (state, action) => {
				return {
					...state,
					selectedCompany: action.payload
				};
			},
		}
	});

	return companySlice.reducer;
}

export const updateCompany = createAction("company/update");
export const setCompany = createAction("company/selectedCompany");