import React from "react";
import styles from "../../../pages/blogs/BlogPage.module.css";

const AuthorInfo = ({ post }) => {
  return (
    <>
      <footer className={styles.blogFooter}>
        <img src={post.author.avatar} alt="" className={styles.authorAvatar} />
        <div className={styles.authorInfo}>
          <div className="df aife">
            <span className={styles.authorName}>{post.author.name}</span>
            <span className={styles.authorRole}>{post.author.role}</span>
          </div>
          <time className={styles.postDate}>{post.date}</time>
        </div>
      </footer>
    </>
  );
};

export default AuthorInfo;
