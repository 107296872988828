import React from 'react'
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";

import CoreWorkplace from "./CoreWorkplace";
import ChecklistModules from "./ChecklistModules";
import ChecklistWorkflows from "./ChecklistWorkflows";

export default function PortfolioDashboard({ company, companyId, setTab, setDashboardTab, setCompanyDashboardTab, setIsWorkflowReport }) {

	const dispatch = useDispatch();
	let user = useSelector((state) => state.user);

	let [protfolioProTab, setProtfolioProTab] = useState("Core Workplace");
	let [modulesForChecklist, setModulesForChecklist] = useState([]);


	return (
		<>
			<div className='dashboard-tools-area'>
				<div className='box-dashbord-container mla mra mb6rem'>
					<div className='tabs-navlist tabs-dashboard df aic jcc mt2rem mb2rem'>{
						['Core Workplace', 'Checklist Modules', 'Checklist Workflows'].map(item => {
							return <div className="df fdc">
								{(item == 'Checklist Modules' || item == 'Checklist Workflows') && <span className="c4 fs0-75rem" style={{ marginTop: '-15px', color: '#8E8E8E' }}>{user['role']}</span>}
								<button className={`fs1-25rem fw500 c15 cp ${protfolioProTab == item && ' active'}`} onClick={() => setProtfolioProTab(item)}>{item}</button>
							</div>
						})
					}
					</div>
					{protfolioProTab == 'Core Workplace' && <CoreWorkplace setTab={setTab} setDashboardTab={setDashboardTab} setCompanyDashboardTab={setCompanyDashboardTab} />}
					{protfolioProTab == 'Checklist Modules' && <ChecklistModules company={company} setTab={setTab} setIsWorkflowReport={setIsWorkflowReport} setDashboardTab={setDashboardTab} setCompanyDashboardTab={setCompanyDashboardTab} />}
					{protfolioProTab == 'Checklist Workflows' && <ChecklistWorkflows company={company} setTab={setTab} setIsWorkflowReport={setIsWorkflowReport} setDashboardTab={setDashboardTab} setCompanyDashboardTab={setCompanyDashboardTab} />}
				</div>
			</div>
		</>
	);
};
