import moment from "moment";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { postRequest, showMessage } from '../../../../config.js';

export default function TeamPermissions({ company, setCompany }) {

	const dispatch = useDispatch();

	let user = useSelector((state) => state.user);
	let modal = useSelector((state) => state.modal);
	let companyId = location.pathname.split("/").slice(-1)[0];


	const setPermissions = async (e, section, member) => {
		let permissions = company?.['memberPermissions']?.[member['_id']];
		permissions[section['sectionNo']] = e.target.checked;

		if (company?.['memberPermissions']?.[member['_id']]) {
			company['memberPermissions'][member['_id']] = permissions;
			setCompany(st => ({ ...st, company }));
			savePermissions(section['sectionNo'], member, e.target.checked);
		}
	};

	const savePermissions = async (sectionNo, member, action) => {
		// User Updated
		const res = await postRequest(`/company/updatePermission/${companyId}`, { permissions: company['memberPermissions'], sectionNo, member, action });
		if (res || res['code'] == 200) showMessage(res['message']);
	};


	return (
		<>
			<div class="sectionWrapper p1rem fs0-75rem pr mr2rem ml1rem pr mb2rem">
				<div className="table-permission">
					<table id="table" className="table-userPermissions table-clientPermissions">
						<thead className="ps t0">
							<tr>
								<th className="index-table">Modules</th>
								{company['creatorId'] &&
									<th className='user-area'>
										<div class="df jcc aic">
											<div className='mr1rem'>
												{company['creatorId']?.['profile']
													? <img data-tooltip={company['creatorId']?.firstName + " " + company['creatorId']?.lastName} src={company['creatorId']?.['profile']} class={`shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31 `} />
													: <span data-tooltip={company['creatorId']?.firstName + " " + company['creatorId']?.lastName} class={`shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31 `}>{company['creatorId']?.firstName ? (company['creatorId']?.firstName[0] + (company['creatorId']?.lastName ? company['creatorId']?.lastName[0] : "")) : "?"}</span>
												}
											</div>
											<span>{company['creatorId']['firstName']} {company['creatorId']['lastName']} <br /> (Owner)</span>
										</div>
										<div className='df jcc fdc'>
											<p class='fs0-75rem ml3rem'>Status: <span className='c7 mr0-5rem'>Activated </span></p>
											{company['creatorId']?.['lastLogin'] && <p class='fs0-75rem ml3rem'> Last Login: <span className={((new Date().getTime() - new Date(company['creatorId']['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(company['creatorId']['lastLogin']).format('ll')}</span></p>}
										</div>
									</th>
								}
								{company['members'] && company['members'].map(member => {
									return (
										<th className='user-area'>
											<div class="df jcc aic cp" onClick={() => handleMemberEdit(member)}>
												<div className='mr1rem'>
													{member?.profile
														? <img data-tooltip={member?.firstName + " " + member?.lastName} src={member?.profile} class="shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31" />
														: <span data-tooltip={member?.firstName + " " + member?.lastName} class="shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31">{member?.firstName ? (member?.firstName[0] + member?.lastName[0]) : "?"}</span>
													}
												</div>
												<span>{member['firstName']} {member['lastName']} <br /> ({member['designation']})</span>
											</div>
											<div className='df jcc fdc fs0-75rem ml3rem'>
												<p>Status: <span className={`${member['emailVerified'] ? ' c7' : ' c8'} mr0-5rem`}>{member['emailVerified'] ? 'Activated' : <span>Invite Sent - ({moment(member['updatedAt']).format('ll')})</span>}</span></p>
												{member['emailVerified'] && <p> Last Login: <span className={((new Date().getTime() - new Date(member['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(member['lastLogin']).format('ll')}</span></p>}
											</div>
										</th>
									)
								})}
							</tr>
						</thead>
						<tbody>
							{company['sections'] && company['sections'].map((section, idx) => {
								return <tr>
									{/* Map Sections */}
									<td className='c6' key={idx}>
										<span className='fw600'>{section['sectionNo']}</span> {section['sectionName']}
									</td>
									{/* Map Owner Permission */}
									<td className='c6 cp' key={idx}>
										<label for={`owner-permission-${idx}`} class="df jcc cp fs0-75rem">
											<span className='checkbox w2rem h2rem pr'>
												<input disabled={true} defaultChecked={true} id={`permission-${idx}`} class="shadow bw2px bss cp td250 w2rem h2rem br5px" type="checkbox" style={{ backgroundColor: '#8f8f8f', borderColor: '#8f8f8f' }} />
											</span>
										</label>
									</td>
									{/* Map Members Permission of that section */}
									{company['members'] && company['members'].map((member, idx) => {
										return (
											<td className='c6 cp' key={idx}>
												<label for={`member-permission-${idx}`} class="df jcc cp fs0-75rem">
													<span className='checkbox w2rem h2rem pr'>
														<input checked={company?.['memberPermissions']?.[member['_id']]?.[section['sectionNo']]} onChange={(e) => setPermissions(e, section, member)} id={`${section['sectionNo']}-${member['_id']}`} class="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="checkbox" />
													</span>
												</label>
											</td>
										)
									})}
								</tr>
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
