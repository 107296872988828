/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "../../BlogPost.module.css";

function DefaultCard({ title, titlePrefixIcon, features, imageSrc, maxWidth }) {
  return (
    <>
      <div className={styles.featureContent}>
        <div className={styles.blogBannerCardTitle}>
          <img
            src={titlePrefixIcon}
            alt={`${title} illustration`}
            className={styles.featureTitleImage}
          />
          <h2 className={styles.featureTitle}>{title}</h2>
        </div>
        <ul className={styles.featureList}>
          {features &&
            features.length &&
            features.map((feature, index) => (
              <li key={index} className={styles.featureItem}>
                {feature}
              </li>
            ))}
        </ul>
      </div>
      <img
        src={imageSrc}
        alt={`${title} illustration`}
        className={styles.featureImage}
        style={{maxWidth: maxWidth}}
      />
    </>
  );
}

export default DefaultCard;
