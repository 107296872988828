import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  blogPosts,
  featuredPost,
} from "../../../components/blogs/data/blogData";
import BlogPost from "../../../components/blogs/blogDetails/BlogPost";
import BlogDetailWarpper from "./BlogDetailWarpper";

const BlogDetailPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (slug) {
      const selectedData = [...blogPosts, featuredPost].filter((blog) => {
        return blog.slug === slug;
      });
      if (selectedData.length) setData(selectedData[0]);
    }
  }, [slug]);

  if (!data) {
    return (
      <>
        <p>...loading</p>
      </>
    );
  }

  return (
    <>
      <BlogDetailWarpper
        bannerImage={data.bannerImage}
        title={data.title}
        categories={data.category}
      >
        <BlogPost post={data} />
      </BlogDetailWarpper>
    </>
  );
};

export default BlogDetailPage;
