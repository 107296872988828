import React from "react";

function BlogHero({ handleActiveTab, tab, handleSearch }) {
  return (
    <section
      className="pt4rem pl4rem pr4rem mih400px df jcc aic fdc bscon bpc brnr"
      style={{
        backgroundImage: `url(${require(`../../../../images/blogs/blogHeaderBg.png`)})`,
      }}
    >
      <h1 className="fs3rem poppins-font-style c15 mb1rem">Blog</h1>
      <form className="bw1px br50px bss bg1 bo31-xl w500px maw100% df oh aic">
        <input
          type="search"
          id="searchInput"
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          className="pr1rem pl1rem fs1-25rem fw4 c15 w100"
        />
        <button type="submit" className="cp bg-none p0-5rem df aic jcc">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f6f736ac76e1958b0e559b92111565c1c3bffafb32c397dc178def004a3de998?placeholderIfAbsent=true&apiKey=0e804629346e4662b140bf48de8efc48"
            alt="Search"
            className="w46px"
          />
        </button>
      </form>
      <nav className="mt3rem ">
        <ul className="lst jcsb df">
          {["All", "AI", "Companies", "Accountants", "Lawyers", "VCs", "PEs", "Angel Networks", "Angels",].map((category, index) => (
            <li className="px1rem cp" key={index}>
              <p onClick={() => handleActiveTab(category)} className={`tc fs1-25rem fw400 ${tab === category ? "c40 pr fw6 activeTabBlog" : "c15 op60"}`}>
                {category}
              </p>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  );
}

export default BlogHero;
